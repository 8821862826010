<template>
  <slideshow
    :slides="usedSlides"
    :slidesToShow="1"
    :hasDots="true"
    :hasAnimations="false"
  >
    <template #content="{ slide }">
      <div class="quote-cell">
        <div class="desktop p-5 m-5 t-primary">
          <img src="/assets/img/quote.svg" class="quotes-top">
          <img src="/assets/img/quote.svg" class="quotes-top quotes-top-last">

          <p class="quote-text desktop lg t-primary">
            {{ slide.quote }}
          </p>
          <p class="quote-author desktop ml-2">
            {{ slide.name }}
          </p>
          <img
            src="/assets/img/quote-inverted.svg"
            class="quotes-bottom quotes-bottom-first"
          >
          <img src="/assets/img/quote-inverted.svg" class="quotes-bottom">
          <div class="container-fluid q-t p-t">
            <div class="row mt-5 t-center mx-auto quote-table">
              <div class="col-3 quote-table-line">
                <p class="mt-3 mb-1 quote-table-top desktop">
                  {{ slide.sq1.line1 }}
                </p>
                <p class="mt-1 mb-3 quote-table-bottom desktop">
                  {{ slide.sq1.line2 }}
                </p>
              </div>
              <div class="col-6 quote-table-line">
                <p class="mt-3 mb-1 quote-table-top desktop">
                  {{ slide.sq2.line1 }}
                </p>
                <p class="mt-1 mb-3 quote-table-bottom desktop">
                  {{ slide.sq2.line2 }}
                </p>
              </div>
              <div class="col-3">
                <p class="mt-3 mb-1 quote-table-top desktop">
                  {{ slide.sq3.line1 }}
                </p>
                <p class="mt-1 mb-3 quote-table-bottom desktop">
                  {{ slide.sq3.line2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile p-4 p-sm-5 t-primary">
          <img src="assets/img/quote.svg" class="quotes-top mobile">
          <img src="assets/img/quote.svg" class="quotes-top quotes-top-last mobile">
          <p class="quote-text mobile lg t-primary mb-1">
            {{ slide.quote }}
          </p>
          <p class="quote-author mobile ml-2 mb-0">
            {{ slide.name }}
          </p>
          <img
            src="assets/img/quote-inverted.svg"
            class="quotes-bottom quotes-bottom-first mobile"
          >
          <img src="assets/img/quote-inverted.svg" class="quotes-bottom mobile">
          <div class="row mt-4 t-center mx-4 mx-sm-1 quote-table">
            <div class="col-6 quote-table-line px-1">
              <p class="mt-3 mb-1 quote-table-top mobile">
                {{ slide.sq1.line1 }}
              </p>
              <p class="mt-1 mb-3 quote-table-bottom mobile">
                {{ slide.sq1.line2 }}
              </p>
            </div>
            <div class="col-6 px-1">
              <p class="mt-3 mb-1 quote-table-top mobile">
                {{ slide.sq3.line1 }}
              </p>
              <p class="mt-1 mb-3 quote-table-bottom mobile">
                {{ slide.sq3.line2 }}
              </p>
            </div>
            <div class="col-12 quote-table-line-top">
              <p class="mt-3 mb-1 quote-table-top mobile">
                {{ slide.sq2.line1 }}
              </p>
              <p class="mt-1 mb-3 quote-table-bottom mobile">
                {{ slide.sq2.line2 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </slideshow>
</template>
<script>
import Slideshow from '../utils/slideshow/slideshow.vue'
export default {
	name: 'QuoteCarousel',
	components: { Slideshow },
  props: {
    cells: { type: String, required: true }
  },
  computed:{
    usedSlides(){
      var slides = JSON.parse(this.cells.replaceAll('=>', ':'))
      var i
      for( i = 0; i < slides.length; i++){
        slides[i]['index'] = i
      }
      return slides
    }
  }
}
</script>
<style>
</style>
