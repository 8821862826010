<template>
  <!-- Slideshow container -->
  <div class="slideshow-container row">
    <transition-group v-if="hasAnimations" :name="transitionName">
      <div
        v-for="slide in visibleSlides"
        :key="slide.index"
        :id="slide.index"
        :class="{ 'col-sm-12': true, [columnCalc]: true, [paddingDefault]: true }"
      >
        <slot name="content" :slide="slide" :index="slide.index" />
      </div>
    </transition-group>
    <div
      v-else
      v-for="slide in visibleSlides"
      :key="slide.index"
      :id="slide.index"
      :class="{ 'col-sm-12': true, [columnCalc]: true, [paddingDefault]: true }"
    >
      <slot name="content" :slide="slide" :index="slide.index" />
    </div>

    <!-- Next and previous buttons -->
    <a :class="{ prev: true, 'is-rounded': isRounded }" @click="plusSlides(-1)">
      <img class="chevron" :src="leftButton">
    </a>
    <a :class="{ next: true, 'is-rounded': isRounded }" @click="plusSlides(1)">
      <img class="chevron" :src="rightButton">
    </a>
  </div>
  <br>
  <!-- The dots/circles -->
  <div v-if="hasDots" class="slideshow-dots" style="text-align: center">
    <span
      v-for="(slide, index) in slides"
      :key="index"
      :class="{ dot: true, active: slideIndex === index }"
      @click="currentSlide(index)"
    />
  </div>
</template>

<script>
	export default {
		name: 'Slideshow',
		props: {
			slides: {
				type: Array,
				required: true,
			},
			wrapAround: {
				type: Boolean,
				required: false,
				default: true,
			},
			hasDots: {
				type: Boolean,
				required: false,
				default: false,
			},
			hasAnimations: {
				type: Boolean,
				required: false,
				default: true,
			},
			isRounded: {
				type: Boolean,
				required: false,
				default: false,
			},
			slidesToShow: {
				type: Number,
				required: false,
				default: 1,
			},
			slideChosen: {
				type: Number,
				required: false,
				default: 0,
			},
			width: {
				type: Number,
				required: false,
				default: 100,
			},
			leftButton: {
				type: String,
				required: false,
				default: '/assets/icons/black/chevron_left.svg',
			},
			rightButton: {
				type: String,
				required: false,
				default: '/assets/icons/black/chevron_right.svg',
			},
			paddingDefault: {
				type: String,
				required: false,
				default: 'px-2',
			},
		},
		emits: ['updatedSlideshowIndex'],
		data() {
			return {
				slideIndex: 0,
				direction: 1,
			}
		},
		computed: {
			visibleSlides() {
				if (this.slidesToShow + this.slideIndex - 1 >= this.slides.length) {
					var tgBack = this.slides.length - this.slideIndex
					var tgFront = this.slidesToShow - tgBack
					return this.slides.slice(tgBack * -1).concat(this.slides.slice(0, tgFront))
				}
				return this.slides.slice(this.slideIndex, this.slidesToShow + this.slideIndex)
			},
			columnCalc() {
				return 'col-xl-' + 12 / this.slidesToShow
			},
			transitionName() {
				return this.direction > 0 ? 'carousel-slide-left' : 'carousel-slide-right'
			},
		},
		watch: {
			slideChosen(newVal,oldVal) {
        if(!oldVal) this.slideIndex = newVal
				//if (this.slidesToShow > 1) this.slideIndex = newVal > 0 ? newVal - 1 : this.slides.length - 1
				//else this.slideIndex = newVal
			},
      slideIndex(newVal){
        this.$emit('updatedSlideshowIndex',newVal)
      }
		},
		mounted() {
			this.slideIndex = this.slideChosen
		},
		methods: {
			currentSlide(id) {
				this.slideIndex = id
			},
			plusSlides(n) {
				this.direction = n
				if (this.wrapAround || (this.slideIndex + n >= 0 && this.slideIndex + n < this.slides.length))
					this.showSlides((this.slideIndex += n))
			},
			showSlides(n) {
				if (n >= this.slides.length) {
					this.slideIndex = 0
				}
				if (n < 0) {
					this.slideIndex = this.slides.length - 1
				}
			},
		},
	}
</script>

<style lang="scss">
	@import '../../../../assets/sass/inc/variables';
	/* Slideshow container */
	.slideshow-container {
		max-width: 1000px;
		position: relative;
		margin: auto;
	}

	/* Next & previous buttons */
	.prev,
	.next {
		cursor: pointer;
		position: absolute;
		top: 50%;
		width: auto;
		margin-top: -22px;
		padding: 16px;
		font-size: 18px;
		transition: 0.6s ease;
		border-radius: 0 3px 3px 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-webkit-tap-highlight-color: transparent;
		&.is-rounded {
			border: 1px solid $brand-primary;
			border-radius: 50%;
			padding: 5px;
		}
	}
	/* Position the "next button" to the right */
	.next {
		right: -35px;
		border-radius: 3px 0 0 3px;
	}
	.prev {
		left: -35px;
	}

	/* Caption text */
	.slideshow-dots {
		position: relative;
		bottom: 50px;
	}
	/* The dots/bullets/indicators */
	.dot {
		cursor: pointer;
		height: 15px;
		width: 15px;
		margin: 0 2px;
		background-color: #bbb;
		border-radius: 50%;
		display: inline-block;
		transition: background-color 0.6s ease;
	}

	.active,
	.dot:hover {
		background-color: #717171;
	}

	/* Fading animation */
	.fade {
		-webkit-animation-name: fade;
		-webkit-animation-duration: 1.5s;
		animation-name: fade;
		animation-duration: 1.5s;
	}

	@-webkit-keyframes fade {
		from {
			opacity: 0.4;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fade {
		from {
			opacity: 0.4;
		}
		to {
			opacity: 1;
		}
	}

	.carousel-slide-left-enter-active,
	.carousel-slide-left-leave-active {
		transition: all 0.6s ease-in-out;
	}

	.carousel-slide-left-enter-from {
		right: -5%;
		opacity: 0;
	}

	.carousel-slide-left-enter-to {
		right: 0;
	}

	.carousel-slide-left-leave-to,
	.carousel-slide-left-leave-from {
		position: absolute;
		opacity: 0;
	}

	.carousel-slide-left-move {
		transition: transform 0.6s ease-in-out;
	}

	.carousel-slide-right-enter-active,
	.carousel-slide-right-leave-active {
		transition: all 0.6s ease-in-out;
	}

	.carousel-slide-right-enter-from {
		left: -5%;
		opacity: 0;
	}
	.carousel-slide-right-enter-to {
		left: 0;
	}

	.carousel-slide-right-leave-to,
	.carousel-slide-right-leave-from {
		position: absolute;
		opacity: 0;
	}

	.carousel-slide-right-move {
		transition: transform 0.6s ease-in-out;
	}
</style>
